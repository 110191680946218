"use client"

import { useEffect } from "react"

import { useTranslations } from "next-intl"

import { useMe } from "@supernovaio/cloud//hooks/data/useMe"

import { useImpersonation } from "./useImpersonation"
import { enableImpersonation, removeImpersonation } from "./utils"

export function ImpersonationBanner() {
  const t = useTranslations("Impersonation")
  const { data: me } = useMe()
  const { isImpersonating } = useImpersonation()

  useEffect(() => {
    enableImpersonation()
  }, [])

  if (!isImpersonating) {
    return null
  }

  return (
    <div className="border-primary-faded bg-critical flex items-center justify-between p-8 pl-16 pr-16">
      <div className="flex items-center gap-12">
        <p className="text-white">
          {t("impersonating", {
            name: me?.profile.name,
            email: me?.email,
          })}
        </p>
      </div>
      <button
        type="button"
        className="text-bold shrink-0 font-semibold text-white hover:underline"
        onClick={() => {
          removeImpersonation()
        }}
      >
        {t("exit")}
      </button>
    </div>
  )
}
