"use client"

import { useEffect } from "react"

import { useMe } from "../../hooks/data/useMe"

import { useLDClient } from "launchdarkly-react-client-sdk"

export function FeatureFlagsIdentify() {
  const { data: me } = useMe()

  const client = useLDClient()

  useEffect(() => {
    if (!client || !me) {
      return
    }

    ;(async () => {
      await client.identify({
        key: me.id,
        email: me.email,
      })
    })()
  }, [client, me])

  return null
}
