"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental"

import {
  is404Error,
  isForbiddenAccessError,
  isSupernovaError,
  isUnauthorizedError,
} from "./errorHandler"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1, // 1 minute
      throwOnError: (error) => {
        // Only unknown errors should be caught by the error boundary
        return (
          !isSupernovaError(error) &&
          !is404Error(error) &&
          !isForbiddenAccessError(error) &&
          !isUnauthorizedError(error)
        )
      },
    },
  },
})

export default function QueryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
      <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  )
}
