"use client"

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { useStorageValueMap } from "../hooks/usePersistentStorage"

import { z } from "zod"

type Context = {
  tokensSelected: Array<string>
  setTokensSelected: React.Dispatch<React.SetStateAction<Array<string>>>
  deselectAll: () => void
}

const SelectionContext = createContext<Context>({
  tokensSelected: [],
  setTokensSelected: () => null,
  deselectAll: () => null,
})

const selectionContextDataSchema = z.object({
  tokensSelected: z.array(z.string()),
})

const SELECTION_STATE = "selectionState" as const

type SelectionState = z.infer<typeof selectionContextDataSchema>

export function SelectionStateProvider({ children }: React.PropsWithChildren) {
  const map = useStorageValueMap(selectionContextDataSchema, (storage) =>
    storage.getItem(SELECTION_STATE)
  )

  const [selectionState, setSelectionState] = useState<SelectionState>(
    map((state) => state) || { tokensSelected: [] }
  )

  const [tokensSelected, setTokensSelected] = useState<Array<string>>(
    selectionState.tokensSelected
  )

  const deselectAll = useCallback(() => {
    setTokensSelected(() => {
      return []
    })
  }, [])

  useEffect(() => {
    setSelectionState({ tokensSelected })
  }, [tokensSelected, deselectAll])

  const context = useMemo(
    () => ({
      tokensSelected,
      setTokensSelected,
      deselectAll,
    }),
    [tokensSelected, deselectAll]
  )

  return (
    <SelectionContext.Provider value={context}>
      {children}
    </SelectionContext.Provider>
  )
}

export const useSelectionContext = () => {
  return useContext(SelectionContext)
}
