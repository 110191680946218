import { useClosestEligiblePlan } from "@supernovaio/cloud/hooks/useClosestEligiblePlan"
import {
  WorkspaceSubscriptionPlanInterval,
  WorkspaceSubscriptionProductCode,
  WorkspaceSubscriptionStatus,
} from "@supernovaio/sdk"
import { WorkspaceProduct } from "@supernovaio/sdk/src/model/workspaces/SDKWorkspaceProduct"

export const PRODUCT_LEVELS: {
  [key in WorkspaceSubscriptionProductCode]: number
} = {
  [WorkspaceSubscriptionProductCode.free]: 0,
  [WorkspaceSubscriptionProductCode.team]: 1,
  [WorkspaceSubscriptionProductCode.company]: 2,
  [WorkspaceSubscriptionProductCode.enterprise]: 3,
  [WorkspaceSubscriptionProductCode.teamTest]: 5,
} as const

export const NEXT_LEVEL_PRODUCT: {
  [key in WorkspaceSubscriptionProductCode]:
    | WorkspaceSubscriptionProductCode.team
    | WorkspaceSubscriptionProductCode.company
    | WorkspaceSubscriptionProductCode.enterprise
    | undefined
} = {
  [WorkspaceSubscriptionProductCode.free]:
    WorkspaceSubscriptionProductCode.team,
  [WorkspaceSubscriptionProductCode.team]:
    WorkspaceSubscriptionProductCode.company,
  [WorkspaceSubscriptionProductCode.teamTest]:
    WorkspaceSubscriptionProductCode.company,
  [WorkspaceSubscriptionProductCode.company]:
    WorkspaceSubscriptionProductCode.enterprise,
  [WorkspaceSubscriptionProductCode.enterprise]: undefined,
} as const

export const PREVIOUS_LEVEL_PRODUCT: {
  [key in WorkspaceSubscriptionProductCode]:
    | WorkspaceSubscriptionProductCode.team
    | WorkspaceSubscriptionProductCode.company
    | WorkspaceSubscriptionProductCode.enterprise
    | WorkspaceSubscriptionProductCode.free
} = {
  [WorkspaceSubscriptionProductCode.free]:
    WorkspaceSubscriptionProductCode.free,
  [WorkspaceSubscriptionProductCode.team]:
    WorkspaceSubscriptionProductCode.free,
  [WorkspaceSubscriptionProductCode.teamTest]:
    WorkspaceSubscriptionProductCode.team,
  [WorkspaceSubscriptionProductCode.company]:
    WorkspaceSubscriptionProductCode.team,
  [WorkspaceSubscriptionProductCode.enterprise]:
    WorkspaceSubscriptionProductCode.company,
} as const

export const isTargetProductAnUpgrade = (
  currentPlan: WorkspaceSubscriptionProductCode,
  targetPlan: WorkspaceSubscriptionProductCode
) => PRODUCT_LEVELS[currentPlan] < PRODUCT_LEVELS[targetPlan]

export const isCurrentProductHigherThanTargetProduct = (
  currentPlan: WorkspaceSubscriptionProductCode,
  targetPlan: WorkspaceSubscriptionProductCode
) => PRODUCT_LEVELS[currentPlan] > PRODUCT_LEVELS[targetPlan]

type FindPriceType = {
  prices?: WorkspaceProduct["prices"]
  interval: WorkspaceSubscriptionPlanInterval
}

export const findPrice = ({ interval, prices }: FindPriceType) =>
  prices?.find(
    (price) =>
      price.interval === interval && !price.legacyVersion && price.active
  )

export const isFeatureForbiddenBySuspended = ({
  status,
  valueSearcher,
  featureValue,
}: {
  status: WorkspaceSubscriptionStatus
  valueSearcher: Parameters<typeof useClosestEligiblePlan>["0"]["valueSearcher"]
  featureValue?: number | boolean
}) => {
  if (status !== WorkspaceSubscriptionStatus.suspended) {
    return false
  }

  if (valueSearcher?.param === "enabled" && featureValue) {
    return false
  }

  if (valueSearcher?.param === "max" && valueSearcher.value && featureValue) {
    return featureValue < valueSearcher.value
  }

  return true
}
