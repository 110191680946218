"use client"

import React, { useCallback, useState } from "react"

import { useTranslations } from "next-intl"

import { useDMToast } from "@supernovaio/dm"

import {
  createErrorToastEvent,
  useSegment,
} from "@supernovaio/cloud/features/segment"
import { useWorkspaceSubscription } from "@supernovaio/cloud/hooks/data/useWorkspaceSubscription"
import { useSettingsRoute } from "@supernovaio/cloud/hooks/routing/useSettingsRoute"
import { useParams } from "@supernovaio/cloud/hooks/useParams"
import { ToastSupportButton } from "@supernovaio/cloud/ui/components/ToastSupportButton"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { enrichLinkWithOrigin } from "@supernovaio/cloud/utils/links"
import { SettingsRouteDestination } from "@supernovaio/cloud/utils/routing/types"
import { sureOf } from "@supernovaio/cloud/utils/typeSafety"
import { WorkspaceSubscriptionUpdateModel } from "@supernovaio/sdk"

type UpdateSubscriptionArguments =
  | {
      returnUrl?: string
      newPriceId?: string
      newSeatLimit?: number
      isCancel?: boolean
    }
  | undefined

export function useUpdateSubscription() {
  const t = useTranslations("Subscription.Toast")
  const toast = useDMToast()
  const { trackEvent } = useSegment()
  const { wsId } = useParams<true>()
  const { data: subscription } = useWorkspaceSubscription({
    workspaceId: wsId,
  })
  const [isPending, setIsPending] = useState<boolean>(false)
  const defaultRedirectUrl = useSettingsRoute(
    SettingsRouteDestination.wsBilling
  )
  const rawRemoteSubscription = subscription?.rawRemoteSubscription

  const handleUpdateSubscription = useCallback(
    async ({
      returnUrl,
      newPriceId,
      newSeatLimit,
      isCancel,
    }: UpdateSubscriptionArguments = {}) => {
      setIsPending(true)
      try {
        // For upgrade stripeSubscriptionId must be defined.
        if (!rawRemoteSubscription?.stripeSubscriptionId) {
          return {
            redirectUrl: "",
          }
        }

        const updateObject: WorkspaceSubscriptionUpdateModel | null =
          newPriceId || newSeatLimit
            ? {
                numberOfSeats: newSeatLimit || rawRemoteSubscription.seatLimit,
                priceId: newPriceId || rawRemoteSubscription.planPriceId,
              }
            : null

        const sdk = await getClientSdk()

        const response = await sdk.workspaces.createPortalSession(
          wsId,
          enrichLinkWithOrigin(returnUrl || sureOf(defaultRedirectUrl)),
          updateObject,
          isCancel
        )

        return {
          redirectUrl: response,
        }
      } catch (e) {
        const toastId = toast.show({
          preset: "error",
          text: t("errorCreateSession"),
          actionsSlot: <ToastSupportButton getToastId={() => toastId} />,
        })

        await trackEvent(
          createErrorToastEvent({
            failedAction: "subscription_update",
            domain: "workspace",
          })
        )
      } finally {
        setIsPending(false)
      }
    },
    [rawRemoteSubscription, t, toast, wsId, defaultRedirectUrl, trackEvent]
  )

  return {
    handleUpdateSubscription,
    isPending,
  }
}
