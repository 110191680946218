"use client"

import { useEffect } from "react"

import { useSegment } from "@supernovaio/cloud/features/segment/SegmentProvider"
import { SegmentUserData } from "@supernovaio/cloud/features/segment/types"
import { useMe } from "@supernovaio/cloud/hooks/data/useMe"

export function SegmentIdentifier() {
  const { identify } = useSegment()
  const { data: me } = useMe()

  useEffect(() => {
    ;(async () => {
      if (me) {
        const isSupernovaUser =
          me.email.endsWith("@supernova.io") ||
          me.email.endsWith("@supernova.dev") ||
          me.email.endsWith("@sudolabs.io")

        const data: SegmentUserData = {
          id: me.id,
          email: me.email,
          isSupernovaUser,
          name: me.profile.name || undefined,
          nickname: me.profile.nickname || undefined,
          onboardingDepartment: me.profile.onboarding?.department || undefined,
          onboardingJobTitle: me.profile.onboarding?.jobTitle || undefined,
          onboardingJobLevel: me.profile.onboarding?.jobLevel || undefined,
          onboardingCompanyName:
            me.profile.onboarding?.companyName || undefined,
          onboardingCompanySize:
            me.profile.onboarding?.numberOfPeopleInOrg || undefined,
          onboardingDesignSystemName:
            me.profile.onboarding?.designSystemName || undefined,
          onboardingDesignTeamSize:
            me.profile.onboarding?.numberOfPeopleInDesignTeam || undefined,
        }

        await identify(data)
      }
    })()
  }, [me, identify])

  return null
}
