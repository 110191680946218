import {
  ProductFeature,
  SubscriptionProductFeatures,
  WorkspaceSubscriptionProductCode,
} from "@supernovaio/sdk"

import { PRODUCT_LEVELS } from "../utils/productUtils"

import { useSubscriptionProductFeatures } from "./data/useSubscriptionProductFeatures"
import {
  EffectiveWorkspaceSubscription,
  useWorkspaceSubscription,
} from "./data/useWorkspaceSubscription"

import { useParams } from "./useParams"

type OmitedProductFeature = Omit<
  ProductFeature[number],
  "errorMessage" | "errorReason"
>

type GetNextEligibleProductType = {
  feature: string
  valueSearcher?: {
    param: keyof OmitedProductFeature
    value: OmitedProductFeature[keyof OmitedProductFeature]
  }
}

type ComputedEligiblePlanProps = {
  productFeatures?: SubscriptionProductFeatures | null
  subscription?: EffectiveWorkspaceSubscription
} & GetNextEligibleProductType

const processEligiblePlan = ({
  feature,
  productFeatures,
  subscription,
  valueSearcher = {
    param: "enabled",
    value: true, // This value is working as minimum value
  },
}: ComputedEligiblePlanProps) => {
  const { featuresSummary, product: currentPlan } = subscription ?? {}

  if (
    !productFeatures ||
    !featuresSummary ||
    !currentPlan ||
    (valueSearcher.param === "enabled" &&
      featuresSummary[feature]?.[valueSearcher.param])
  ) {
    return undefined
  }

  if (
    valueSearcher.param === "max" &&
    valueSearcher.value &&
    Number(featuresSummary[feature]?.[valueSearcher.param]) >=
      Number(valueSearcher.value)
  ) {
    return undefined
  }

  const plans = Object.keys(
    PRODUCT_LEVELS
  ) as WorkspaceSubscriptionProductCode[]

  return plans.find((plan) => {
    const featureValue = productFeatures[plan]?.[feature]?.[valueSearcher.param]

    if (
      PRODUCT_LEVELS[plan] <= PRODUCT_LEVELS[currentPlan] ||
      featureValue === undefined
    ) {
      return false
    }

    if (
      valueSearcher.param === "enabled" &&
      featureValue === valueSearcher.value
    ) {
      return true
    }

    if (
      valueSearcher.param === "max" &&
      valueSearcher.value &&
      featureValue >= valueSearcher.value
    ) {
      return true
    }
  })
}

export const useClosestEligiblePlan = ({
  feature,
  valueSearcher,
}: GetNextEligibleProductType):
  | WorkspaceSubscriptionProductCode
  | undefined => {
  const { wsId } = useParams<true>()
  const { data: productFeatures } = useSubscriptionProductFeatures(wsId)
  const { data: subscription } = useWorkspaceSubscription({ workspaceId: wsId })

  return processEligiblePlan({
    feature,
    productFeatures,
    subscription,
    valueSearcher,
  })
}

// Function
/* This is computed version of the hook, sometimes we need just get eligible plan without re-rendering and inside the condition
 * @returns {WorkspaceSubscriptionProductCode} - Closest eligible Plan if found, otherwise undefined.
 */
export const getClosestEligiblePlan = ({
  productFeatures,
  subscription,
  feature,
  valueSearcher,
}: ComputedEligiblePlanProps) =>
  processEligiblePlan({ feature, productFeatures, subscription, valueSearcher })
