import { WsAndDsExistingUser } from "@supernovaio/cloud/features/members/types"
import {
  createDesignSystemMemberIdsQueryKey,
  fetchDesignSystemMemberIds,
} from "@supernovaio/cloud/hooks/data/useDesignSystemMemberIds"
import {
  createWorkspaceExistingUsersQueryKey,
  fetchWorkspaceExistingUsers,
} from "@supernovaio/cloud/hooks/data/useWorkspaceExistingUsers"
import { queryClient } from "@supernovaio/cloud/utils/QueryProvider"
import { Supernova } from "@supernovaio/sdk"

// This is a singleton class that is used to store the Supernova auth token before Liveblocks is initialized
export class SupernovaLiveblocksAuth {
  private static instance: SupernovaLiveblocksAuth

  private storedToken: string | undefined = undefined

  private workspaceId: string | undefined = undefined

  private designSystemId: string | undefined = undefined

  public static getInstance(): SupernovaLiveblocksAuth {
    if (!SupernovaLiveblocksAuth.instance) {
      SupernovaLiveblocksAuth.instance = new SupernovaLiveblocksAuth()
    }

    return SupernovaLiveblocksAuth.instance
  }

  updateToken(token: string) {
    if (!token) {
      throw new Error("Supernova auth token cannot be empty when updating")
    }
    this.storedToken = token
  }

  updateWorkspaceId(workspaceId: string) {
    this.workspaceId = workspaceId
  }

  updateDesignSystemId(designSystemId: string) {
    this.designSystemId = designSystemId
  }

  async oneTimeToken(room: string | undefined): Promise<{
    token: string
  }> {
    if (!this.storedToken) {
      throw new Error(
        "Supernova auth token must always be provided to use the Editor<>Liveblocks connection"
      )
    }

    const sdk = this.getSdk()

    // We need to check if api is returning unathorized response and if so request session update
    try {
      await sdk.me.me()
    } catch (error) {
      if (error instanceof Error && "status" in error && error.status === 401) {
        window.dispatchEvent(new CustomEvent("request-session-update"))
      }
    }

    return sdk.multiplayer.getMultiplayerToken(room)
  }

  async getWsAndDsExistingUsers() {
    if (!this.storedToken) {
      throw new Error(
        "Supernova auth token must always be provided to use the Editor<>Liveblocks connection"
      )
    }

    if (!this.workspaceId) {
      throw new Error(
        "Workspace id must be provided to use mentions in the editor"
      )
    }

    if (!this.designSystemId) {
      throw new Error(
        "Design system id must be provided to use mentions in the editor"
      )
    }

    const sdk = this.getSdk()

    const [wsExistingUsers, dsExistingUsers] = await Promise.all([
      queryClient.fetchQuery({
        queryKey: createWorkspaceExistingUsersQueryKey({
          workspaceId: this.workspaceId,
        }),
        queryFn: () => fetchWorkspaceExistingUsers(sdk, this.workspaceId),
      }),
      queryClient.fetchQuery({
        queryKey: createDesignSystemMemberIdsQueryKey({
          wsId: this.workspaceId,
          dsId: this.designSystemId,
        }),
        queryFn: () => fetchDesignSystemMemberIds(sdk, this.designSystemId),
      }),
    ])

    const wsAndDsExistingUsers: WsAndDsExistingUser[] =
      dsExistingUsers?.users.flatMap((dsUser) => {
        const wsUserMember = wsExistingUsers?.find(
          (wsExistingUser) => wsExistingUser.user.id === dsUser.userId
        )
        if (!wsUserMember) {
          return [] // Use an empty array to filter out this item
        }
        return [
          {
            ...wsUserMember,
            dsRole: dsUser.designSystemRole,
          } satisfies WsAndDsExistingUser,
        ]
      }) ?? []

    return wsAndDsExistingUsers
  }

  apiToken(): string {
    if (!this.storedToken) {
      throw new Error("Supernova auth token was not yet provided")
    }

    return this.storedToken
  }

  private getSdk() {
    if (!this.storedToken) {
      throw new Error("Supernova auth token was not yet provided")
    }

    return new Supernova(this.storedToken, {
      apiUrl: process.env.NEXT_PUBLIC_SN_API_URL as string,
    })
  }
}
