"use client"

import { createContext, PropsWithChildren, useContext, useMemo } from "react"

import { SegmentPageMetadata } from "@supernovaio/cloud/features/segment/types"

// Create the context
const SegmentPageScopeContext = createContext<SegmentPageMetadata | undefined>(
  undefined
)

type Props = PropsWithChildren & Partial<SegmentPageMetadata>

/*
 * Creates a scope for page tracking in Segment.
 * Any underlying `SegmentPageTracker` component will use the `category` and `name` provided here,
 * unless it has its own values.
 *
 * It's useful to wrap a layout component with this to avoid repeating the same `category` and `name` in multiple pages.
 */
export function SegmentPageScope({ category, name, children }: Props) {
  const contextValue = useMemo(
    () => ({
      category,
      name,
    }),
    [category, name]
  )

  return (
    <SegmentPageScopeContext.Provider value={contextValue}>
      {children}
    </SegmentPageScopeContext.Provider>
  )
}

// Create a custom hook to access the context
export const useSegmentPageScope = (): SegmentPageMetadata | undefined => {
  return useContext(SegmentPageScopeContext)
}
