"use client"

import { useEffect, useRef } from "react"

import { isUnauthorizedError } from "@supernovaio/cloud/utils/errorHandler"

import { useQueryClient } from "@tanstack/react-query"

import { CLEAR_EXPIRED_ACCESS_TOKEN_EVENT_TYPE } from "./AuthDebugOverlay"
import { updateSessionInMemory } from "./sessionInMemory"
import { useSession } from "./useSession"
import { isAuthDebugEnabled } from "./utils"

const REQUEST_SESSION_UPDATE_EVENT_TYPE = "request-session-update"

/**
 * @description A component that requests session update and save session to memory when:
 * 1. API returns unauthorised error during fetching data
 * 2. We trigger event for session request manually
 *
 * Also, when we are using `AuthDebugOverlay` and we expired token manually, expired token will be cleared if we receive valid session after update was requested.
 */
export function SessionUpdater() {
  const { data: session, update } = useSession()
  const queryClient = useQueryClient()
  const isSessionUpdatePending = useRef(false)

  // Handle unathorized errors from api which are handled by react-query
  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe(async (event) => {
      const shouldTryToUpdate =
        event.type === "updated" &&
        (event.action.type === "error" || event.action.type === "failed") &&
        isUnauthorizedError(event.action.error) &&
        !isSessionUpdatePending.current

      if (shouldTryToUpdate) {
        isSessionUpdatePending.current = true

        const newSession = await update()

        if (newSession?.user) {
          isSessionUpdatePending.current = false
        }
      }
    })

    return () => unsubscribe()
  }, [queryClient, update])

  // Try to update session when received request from outside of React
  useEffect(() => {
    const requestSessionUpdateHandler = async () => {
      await update()
    }

    window.addEventListener(
      REQUEST_SESSION_UPDATE_EVENT_TYPE,
      requestSessionUpdateHandler
    )

    return () =>
      window.removeEventListener(
        REQUEST_SESSION_UPDATE_EVENT_TYPE,
        requestSessionUpdateHandler
      )
  }, [update])

  // Update session in memory everytime valid session is received
  useEffect(() => {
    if (session?.user) {
      if (isAuthDebugEnabled()) {
        window.dispatchEvent(
          new CustomEvent(CLEAR_EXPIRED_ACCESS_TOKEN_EVENT_TYPE)
        )
      }

      updateSessionInMemory(session)
    }
  }, [session])

  return null
}
