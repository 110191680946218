import { useCallback, useId } from "react"

import { WorkspaceExistingUser } from "@supernovaio/cloud/features/members/types"
import { createWorkspaceQueryKey } from "@supernovaio/cloud/hooks/data/useWorkspace"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { Supernova } from "@supernovaio/sdk"

import { useQueryClient } from "@tanstack/react-query"
import { UseQueryResult } from "@tanstack/react-query/build/modern"

import { useSafeQuery } from "../useSafeQuery"

type QueryIds = {
  workspaceId: string
}

export const createWorkspaceExistingUsersQueryKey = (ids: QueryIds) => [
  ...createWorkspaceQueryKey({
    wsId: ids.workspaceId,
  }),
  "members",
]

export const fetchWorkspaceExistingUsers = async (
  sdk: Supernova,
  workspaceId: string | undefined
) => {
  if (!workspaceId) {
    return null
  }

  const memberships = await sdk.workspaces.userMemberships(workspaceId)

  // Re-map to workspace users in order to have the roles properly named and prevent any misunderstandings
  // eg. someone using `role` when they should have used `effectiveRole`
  const users: WorkspaceExistingUser[] = memberships.map((membership) => {
    return {
      user: membership.user,
      wsRawRole: membership.role,
      wsEffectiveRole: membership.effectiveRole,
    }
  })

  return users
}

export const useInvalidateWorkspaceExistingUsers = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: createWorkspaceExistingUsersQueryKey(ids),
        }),
      ]),
    [queryClient]
  )
}

export const useWorkspaceExistingUsers = ({
  workspaceId,
  enabled,
}: {
  workspaceId: string | undefined
  enabled?: boolean
}): UseQueryResult<WorkspaceExistingUser[], Error> => {
  const workspaceIdFallback = useId()

  return useSafeQuery({
    queryKey: createWorkspaceExistingUsersQueryKey({
      workspaceId: workspaceId || workspaceIdFallback,
    }),
    queryFn: async () => {
      const sdk = await getClientSdk()

      return fetchWorkspaceExistingUsers(sdk, workspaceId)
    },
    enabled,
  })
}
