"use client"

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import {
  usePersistentStorage,
  useStorageValueMap,
} from "../hooks/usePersistentStorage"

import { z } from "zod"

type Context = {
  sidebarCollapsed: boolean
  toggleSidebarCollapsed: () => void
  setSidebarCollapsed: (isCollapsed: boolean) => void
  cmdkShown: boolean
  toggleCmdk: () => void
  setCmdkShown: React.Dispatch<React.SetStateAction<boolean>>
  getAssetsNavigationSourceExpandedState: (sourceId: string) => boolean
  setAssetsNavigationSourceExpandedState: (
    sourceId: string,
    isExpanded: boolean
  ) => void
  getDocsNavigationGroupExpandedStates: () =>
    | Record<string, boolean>
    | undefined
  getDocsNavigationGroupExpandedState: (groupId: string) => boolean | undefined
  setDocsNavigationGroupExpandedState: (
    groupId: string,
    isExpanded: boolean
  ) => void
  getDocsPropertyPanelSectionState: (sectionId: string) => boolean | undefined
  setDocsPropertyPanelSectionState: (
    sectionId: string,
    isExpanded: boolean
  ) => void
  getApprovalsSuggestionDismissed: (wsId: string, dsId: string) => boolean
  setApprovalsSuggestionDismissed: (
    wsId: string,
    dsId: string,
    approvalsSuggestionDismissed: boolean
  ) => void
}

const UIContext = createContext<Context>({
  sidebarCollapsed: false,
  toggleSidebarCollapsed: () => null,
  setSidebarCollapsed: () => null,
  cmdkShown: false,
  toggleCmdk: () => null,
  setCmdkShown: () => null,
  getAssetsNavigationSourceExpandedState: () => true,
  setAssetsNavigationSourceExpandedState: () => {},
  getDocsNavigationGroupExpandedState: () => undefined,
  setDocsNavigationGroupExpandedState: () => {},
  getDocsNavigationGroupExpandedStates: () => ({}),
  getDocsPropertyPanelSectionState: () => undefined,
  setDocsPropertyPanelSectionState: () => {},
  getApprovalsSuggestionDismissed: () => false,
  setApprovalsSuggestionDismissed: () => {},
})

const uiContextDataSchema = z.object({
  sidebarCollapsed: z.boolean(),
  assetsNavigationSourceExpandedStates: z.record(z.boolean()),
  documentationGroupExpandedStates: z.record(z.boolean()),
  documentationPropertyPanelSectionStates: z.record(z.boolean()),
  approvalsSuggestionDismissed: z.record(z.record(z.boolean())),
})

type UIContextState = z.infer<typeof uiContextDataSchema>

const UI_STATE = "uiState" as const

export function UIContextProvider({ children }: React.PropsWithChildren) {
  const localStorage = usePersistentStorage()

  const map = useStorageValueMap(uiContextDataSchema, (storage) =>
    storage.getItem(UI_STATE)
  )

  const [uiState, setUIState] = useState<UIContextState>(
    map((state) => state) || {
      sidebarCollapsed: false,
      assetsNavigationSourceExpandedStates: {},
      documentationGroupExpandedStates: {},
      documentationPropertyPanelSectionStates: {},
      approvalsSuggestionDismissed: {},
    }
  )

  // Sidebar collapsed
  // --- --- --- --- --- --- --- --- --- ---

  const setSidebarCollapsed = useCallback(
    (isCollapsed: boolean) => {
      const newState = { ...uiState, sidebarCollapsed: isCollapsed }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  const toggleSidebarCollapsed = useCallback(() => {
    setSidebarCollapsed(!uiState.sidebarCollapsed)
  }, [setSidebarCollapsed, uiState.sidebarCollapsed])

  // CMD+k
  // --- --- --- --- --- --- --- --- --- ---

  const [cmdkShown, setCmdkShown] = useState<boolean>(false)

  const toggleCmdk = useCallback(() => {
    setCmdkShown((prev) => {
      return !prev
    })
  }, [])

  // Assets navigation
  // --- --- --- --- --- --- --- --- --- ---

  const getAssetsNavigationSourceExpandedState = useCallback(
    (sourceId: string) => {
      return uiState.assetsNavigationSourceExpandedStates[sourceId] ?? true
    },
    [uiState]
  )

  const setAssetsNavigationSourceExpandedState = useCallback(
    (sourceId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        assetsNavigationSourceExpandedStates: {
          ...uiState.assetsNavigationSourceExpandedStates,
          [sourceId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Docs navigations groups
  // --- --- --- --- --- --- --- --- --- ---

  const getDocsNavigationGroupExpandedStates = useCallback(() => {
    return map((state) => state)?.documentationGroupExpandedStates
  }, [map])

  const getDocsNavigationGroupExpandedState = useCallback(
    (groupId: string) => {
      return uiState.documentationGroupExpandedStates[groupId]
    },
    [uiState]
  )

  const setDocsNavigationGroupExpandedState = useCallback(
    (groupId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        documentationGroupExpandedStates: {
          ...uiState.documentationGroupExpandedStates,
          [groupId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Docs property panel sections
  // --- --- --- --- --- --- --- --- --- ---

  const getDocsPropertyPanelSectionState = useCallback(
    (sectionId: string) => {
      return uiState.documentationPropertyPanelSectionStates[sectionId]
    },
    [uiState]
  )

  const setDocsPropertyPanelSectionState = useCallback(
    (sectionId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        documentationPropertyPanelSectionStates: {
          ...uiState.documentationPropertyPanelSectionStates,
          [sectionId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Approval suggestions
  // --- --- --- --- --- --- --- --- --- ---

  const getApprovalsSuggestionDismissed = useCallback(
    (wsId: string, dsId: string) => {
      return uiState.approvalsSuggestionDismissed[wsId]?.[dsId] ?? false
    },
    [uiState]
  )

  const setApprovalsSuggestionDismissed = useCallback(
    (wsId: string, dsId: string, approvalsSuggestionDismissed: boolean) => {
      const newState = {
        ...uiState,
        approvalsSuggestionDismissed: {
          ...uiState.approvalsSuggestionDismissed,
          [wsId]: {
            ...uiState.approvalsSuggestionDismissed[wsId],
            [dsId]: approvalsSuggestionDismissed,
          },
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Context
  // --- --- --- --- --- --- --- --- --- ---

  const context = useMemo(
    () => ({
      sidebarCollapsed: uiState.sidebarCollapsed,
      toggleSidebarCollapsed,
      setSidebarCollapsed,
      cmdkShown,
      setCmdkShown,
      toggleCmdk,
      getAssetsNavigationSourceExpandedState,
      setAssetsNavigationSourceExpandedState,
      getDocsNavigationGroupExpandedState,
      setDocsNavigationGroupExpandedState,
      getDocsNavigationGroupExpandedStates,
      getDocsPropertyPanelSectionState,
      setDocsPropertyPanelSectionState,
      getApprovalsSuggestionDismissed,
      setApprovalsSuggestionDismissed,
    }),
    [
      uiState.sidebarCollapsed,
      cmdkShown,
      toggleSidebarCollapsed,
      setSidebarCollapsed,
      toggleCmdk,
      getAssetsNavigationSourceExpandedState,
      setAssetsNavigationSourceExpandedState,
      getDocsNavigationGroupExpandedState,
      setDocsNavigationGroupExpandedState,
      getDocsNavigationGroupExpandedStates,
      getDocsPropertyPanelSectionState,
      setDocsPropertyPanelSectionState,
      getApprovalsSuggestionDismissed,
      setApprovalsSuggestionDismissed,
    ]
  )

  return <UIContext.Provider value={context}>{children}</UIContext.Provider>
}

export const useUIContext = () => {
  return useContext(UIContext)
}
