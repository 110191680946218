"use client"

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { LegacyPlanDialog } from "../ui/plan/components/Dialogs/LegacyPlanDialog"

interface LegacyPlanDialogContextInterface {
  openLegacyDialog: () => Promise<boolean>
  closeLegacyDialog: () => void
  isOpen: boolean
}

export const LegacyPlanDialogContext =
  createContext<LegacyPlanDialogContextInterface | null>(null)

export const useLegacyPlanDialog = () => {
  const context = useContext(LegacyPlanDialogContext)

  if (!context) {
    throw new Error(
      "useLegacyPlanDialog must be used within a LegacyPlanDialogContext"
    )
  }

  return context
}

function LegacyPlanDialogProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Internal states
  const resolvers = useRef<{
    resolve: () => void
    reject: () => void
  } | null>(null)

  useEffect(() => {
    setIsLoading(false)
  }, [isOpen])

  const openLegacyDialog = useCallback(async () => {
    setIsOpen(true)

    const promise = new Promise<void>((resolve, reject) => {
      resolvers.current = { resolve, reject }
    })

    try {
      await promise
      return true
    } catch {
      return false
    }
  }, [setIsOpen])

  // Close function might be redundant but we want show loading state and close it after the process is done
  const closeLegacyDialog = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleDialogCTA = (isConfirm?: boolean) => {
    if (!resolvers.current) {
      return
    }

    setIsLoading(true)
    if (isConfirm) {
      resolvers.current.resolve()
    } else {
      resolvers.current.reject()
      setIsOpen(false)
    }
  }

  const contextValue = useMemo(
    () => ({
      openLegacyDialog,
      closeLegacyDialog,
      isOpen,
    }),
    [closeLegacyDialog, openLegacyDialog, isOpen]
  )

  return (
    <LegacyPlanDialogContext.Provider value={contextValue}>
      {children}
      {isOpen && (
        <LegacyPlanDialog
          isLoading={isLoading}
          isOpen={isOpen}
          onCancel={() => handleDialogCTA(false)}
          onConfirm={() => handleDialogCTA(true)}
        />
      )}
    </LegacyPlanDialogContext.Provider>
  )
}

export default LegacyPlanDialogProvider
