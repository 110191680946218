"use client"

import { type ReactNode, useEffect } from "react"

import type { Session } from "next-auth"
import { SessionProvider as NextSessionProvider, signIn } from "next-auth/react"
import { usePathname, useSearchParams } from "next/navigation"

import { PageLayoutTypeCentering } from "@supernovaio/cloud/ui/page/layouts/PageLayoutTypeCentering"
import PageLoader from "@supernovaio/cloud/ui/page/loaders/PageLoader"

import { generateAuthParams, isAuthDebugEnabled } from "./utils"

type Props = {
  children: ReactNode
  session: Session | null
}

export function SessionProvider({ children, session }: Props) {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  useEffect(() => {
    ;(async () => {
      // If session is null trigger signIn page
      if (!session) {
        await signIn(
          "supernovaauth",
          undefined,
          generateAuthParams(
            searchParams.get("email"),
            searchParams.get("sso_provider"),
            pathname === "/signup"
          )
        )
      }
    })()
  }, [session, searchParams])

  return (
    <NextSessionProvider
      session={session}
      // Refresh session every 15 minutes
      refetchInterval={60 * 15}
      // For easier debugging while switching between IDE and browser
      refetchOnWindowFocus={!isAuthDebugEnabled()}
    >
      {session ? (
        children
      ) : (
        <PageLayoutTypeCentering>
          <PageLoader showTitle />
        </PageLayoutTypeCentering>
      )}
    </NextSessionProvider>
  )
}
