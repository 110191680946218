import { useCallback, useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { Supernova } from "@supernovaio/sdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

/**
 * Create a query key for design system members.
 * When dsId is not provided, it will return a key for members from ALL design systems.
 *
 * This key accepts wsId, although it is not used in the query.
 * This is needed to invalidate members in all designs systems in a specific workspace.
 */
export const createDesignSystemMemberIdsQueryKey = (ids: {
  wsId: string
  dsId: string | undefined
}) => {
  const key = ["designSystemMemberIds", ids.wsId]

  if (ids && ids.dsId) {
    key.push(ids.dsId)
  }
  return key
}

export const useInvalidateDesignSystemMemberIds = () => {
  const queryClient = useQueryClient()

  return useCallback(
    ({ wsId, dsId }: { wsId: string; dsId: string | undefined }) => {
      return queryClient.invalidateQueries({
        queryKey: createDesignSystemMemberIdsQueryKey({ wsId, dsId }),
      })
    },

    [queryClient]
  )
}

export function fetchDesignSystemMemberIds(
  sdk: Supernova,
  dsId: string | undefined
) {
  if (!dsId) {
    return undefined
  }

  return sdk.designSystems.designSystemMemberList(dsId)
}

export const useDesignSystemMemberIds = (
  wsId: string | undefined,
  dsId: string | undefined,
  isEnabled?: boolean
) => {
  const fallbackWsId = useId()
  const fallbackDsId = useId()

  return useSafeQuery({
    queryKey: createDesignSystemMemberIdsQueryKey({
      wsId: wsId || fallbackWsId,
      dsId: dsId || fallbackDsId,
    }),
    queryFn: async () => {
      const sdk = await getClientSdk()
      return fetchDesignSystemMemberIds(sdk, dsId)
    },
    enabled: isEnabled,
  })
}
