import { useSession as useNextSession } from "next-auth/react"

import { logout } from "./utils"

export function useSession() {
  return useNextSession({
    required: true,
    async onUnauthenticated() {
      await logout()
    },
  })
}
