"use client"

import { ReactNode } from "react"

import { useAccessToken } from "@supernovaio/cloud/features/auth"
import { WorkspaceExistingUser } from "@supernovaio/cloud/features/members/types"
import { isEditorPlus } from "@supernovaio/editor"
import { SupernovaLiveblocksAuth } from "@supernovaio/editor/liveblocks.auth"

import { LiveblocksProvider } from "@liveblocks/react/suspense"

export function SupernovaLiveblocksProvider({
  children,
}: {
  children: ReactNode
}) {
  const accessToken = useAccessToken()
  // Update sdk token is we can authenticate with liveblocks for the page presence
  SupernovaLiveblocksAuth.getInstance().updateToken(accessToken)

  return (
    <LiveblocksProvider
      authEndpoint={(room) => {
        return SupernovaLiveblocksAuth.getInstance().oneTimeToken(room)
      }}
      resolveUsers={async ({ userIds }) => {
        const wsAndDsExistingUsers =
          await SupernovaLiveblocksAuth.getInstance().getWsAndDsExistingUsers()

        if (!wsAndDsExistingUsers) {
          return []
        }

        const dsMembersById = new Map<string, WorkspaceExistingUser>()
        wsAndDsExistingUsers.forEach((member) => {
          dsMembersById.set(member.user.id, member)
        })

        return userIds.map((userId) => {
          const existingUser = dsMembersById.get(userId)
          return {
            name: existingUser?.user.displayName,
            avatar: existingUser?.user.profile.avatar ?? undefined,
          }
        })
      }}
      resolveMentionSuggestions={async ({ text }) => {
        const wsAndDsExistingUsers =
          await SupernovaLiveblocksAuth.getInstance().getWsAndDsExistingUsers()

        if (!wsAndDsExistingUsers) {
          return []
        }

        return wsAndDsExistingUsers
          .filter(
            (user) =>
              isEditorPlus(user.dsRole || user.wsEffectiveRole) &&
              user.user.displayName.toLowerCase().includes(text.toLowerCase())
          )
          .map((member) => member.user.id)
      }}
    >
      {children}
    </LiveblocksProvider>
  )
}
