import { useTranslations } from "next-intl"

import { DMButtonWhite, useDMToast } from "@supernovaio/dm"

import { useSupport } from "@supernovaio/cloud/features/support/useSupport"

type Props = {
  getToastId: () => string
}

/** Button to show a get support action in toasts. */
export function ToastSupportButton({ getToastId }: Props) {
  const t = useTranslations("ToastSupportButton")
  const { openChat } = useSupport()
  const toast = useDMToast()

  return (
    <DMButtonWhite
      size="small"
      onClick={() => {
        openChat()
        toast.hide(getToastId())
      }}
    >
      {t("label")}
    </DMButtonWhite>
  )
}
