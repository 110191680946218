import { useTranslations } from "next-intl"

import { DMConfirmationDialog } from "@supernovaio/dm"

type Props = {
  onCancel: () => void
  onConfirm: () => void
  isOpen: boolean
  isLoading: boolean
}

export function LegacyPlanDialog({
  isOpen,
  isLoading,
  onCancel,
  onConfirm,
}: Props) {
  const t = useTranslations("Plan.Dialog")

  return (
    <DMConfirmationDialog
      buttonLabelCancel={t("Buttons.cancel")}
      buttonLabelConfirm={t("Buttons.continue")}
      description={t("legacyPlanDescription")}
      isLoading={isLoading}
      open={isOpen}
      title={t("legacyPlanTitle")}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
      onOpenChange={() => onCancel()}
    />
  )
}
