import {
  BillingPlanPageRouteDestination,
  BillingPlanRouterType,
} from "../../utils/routing/types"

import { useBrandRoute } from "./useBrandRoute"
import { useRoutingResolution } from "./useRoutingResolution"

export function useBillingPlanRoute(
  destination: BillingPlanPageRouteDestination
): BillingPlanRouterType | undefined {
  const resolution = useRoutingResolution()
  const brandRoute = useBrandRoute(
    resolution?.wsId,
    resolution?.dsId,
    resolution?.versionId,
    resolution?.brandId
  )

  if (!brandRoute) return undefined

  return `${brandRoute}/plan/${destination}`
}
