"use client"

import Script from "next/script"

import { useSupport } from "@supernovaio/cloud/features/support/useSupport"

export default function SupportInit() {
  const { initIntegration } = useSupport()

  return (
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=f417c105-70f2-4a23-b9e0-b0237f203b51"
      onLoad={initIntegration}
    />
  )
}
