"use client"

import { useState, useEffect } from "react"

import { DMButtonCritical } from "@supernovaio/dm"

import { getIsImpersonating } from "../impersonation"

import { isAuthDebugEnabled } from "./utils"

export const SET_EXPIRED_ACCESS_TOKEN_EVENT_TYPE = "set-expired-access-token"

export const CLEAR_EXPIRED_ACCESS_TOKEN_EVENT_TYPE =
  "clear-expired-access-token"

export function AuthDebugOverlay() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!isAuthDebugEnabled() || getIsImpersonating()) {
      return undefined
    }

    const setExpiredAccessTokenHandler = (event: CustomEventInit<string>) => {
      window.supernova = {
        ...window.supernova,
        expiredAccessToken: event.detail,
      }
    }
    const clearExpiredAccessTokenHandler = () => {
      if (window.supernova) {
        delete window.supernova.expiredAccessToken
      }
    }

    window.addEventListener(
      SET_EXPIRED_ACCESS_TOKEN_EVENT_TYPE,
      setExpiredAccessTokenHandler
    )
    window.addEventListener(
      CLEAR_EXPIRED_ACCESS_TOKEN_EVENT_TYPE,
      clearExpiredAccessTokenHandler
    )

    setIsVisible(true)

    return () => {
      window.removeEventListener(
        SET_EXPIRED_ACCESS_TOKEN_EVENT_TYPE,
        setExpiredAccessTokenHandler
      )
      window.removeEventListener(
        CLEAR_EXPIRED_ACCESS_TOKEN_EVENT_TYPE,
        clearExpiredAccessTokenHandler
      )

      setIsVisible(false)
    }
  }, [])

  const buttonClickHandler = async () => {
    const response = await fetch("/api/auth/expire-access-token", {
      method: "POST",
    })

    const data = (await response.json()) as
      | {
          ok: true
          expiredAt: string
          expiredAccessToken: string
        }
      | { ok: false; code: string }

    if (data.ok) {
      window.dispatchEvent(
        new CustomEvent(SET_EXPIRED_ACCESS_TOKEN_EVENT_TYPE, {
          detail: data.expiredAccessToken,
        })
      )
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className="block fixed bottom-8 right-[64px] z-50 bg-white p-8 rounded-medium border-neutral-faded border">
      <DMButtonCritical onClick={buttonClickHandler}>
        Expire access token
      </DMButtonCritical>
    </div>
  )
}
