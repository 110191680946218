import { useWorkspaceExistingUsers } from "@supernovaio/cloud/hooks/data/useWorkspaceExistingUsers"
import { UserRole } from "@supernovaio/sdk"

export const useWorkspaceOwners = (workspaceId: string | undefined) => {
  // TODO (Seva): replace with a new get contacts endpoint when it's ready
  const { data: users, isPending } = useWorkspaceExistingUsers({
    workspaceId,
  })
  const owners = users?.filter((user) => user.wsRawRole === UserRole.owner)

  return { data: owners, isPending }
}
