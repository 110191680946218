import type { ReactNode } from "react"

import Link from "next/link"

import { CONTACT_SALES } from "@supernovaio/cloud/utils/links"

export function BrText(chunks: ReactNode) {
  return (
    <>
      <br />
      {chunks}
    </>
  )
}

export function BoldTextAlternative(chunks: ReactNode) {
  return <b className="break-words font-bold">{chunks}</b>
}

export function BoldText(chunks: ReactNode) {
  return <b className="break-words">{chunks}</b>
}

export function SemiBoldText(chunks: ReactNode) {
  return <span className="font-semibold">{chunks}</span>
}

export function StrongText(chunks: ReactNode) {
  return <strong className="break-words">{chunks}</strong>
}

export function ItalicText(chunks: ReactNode) {
  return <i className="break-words">{chunks}</i>
}

export function UnorderedList(chunks: ReactNode) {
  return <ul>{chunks}</ul>
}

export function UnorderedListItem(chunks: ReactNode) {
  return <li className="list-disc list-inside">{chunks}</li>
}

export function ContactSalesLinkText(chunks: ReactNode) {
  return (
    <span className="text-primary underline">
      <Link href={CONTACT_SALES} target="_blank">
        {chunks}
      </Link>
    </span>
  )
}

export function Em(chunks: ReactNode) {
  return <em>{chunks}</em>
}
