"use client"

import { type ReactElement, useEffect } from "react"

import { useMe } from "../hooks/data/useMe"
import {
  usePersistentStorage,
  useStorageValueMap,
} from "../hooks/usePersistentStorage"

import * as z from "zod"

const userIdStorageSchema = z.object({
  userId: z.string(),
})

const USER_ID_STORAGE_KEY = "userId"

export function LocalStorageHandler({ children }: { children: ReactElement }) {
  const localStorage = usePersistentStorage()
  const { data: me } = useMe()

  const map = useStorageValueMap(userIdStorageSchema, (storage) =>
    storage.getItem(USER_ID_STORAGE_KEY)
  )

  useEffect(() => {
    const storedUserId = map(({ userId }) => {
      localStorage.setItem(USER_ID_STORAGE_KEY, { userId })
      return userId
    })

    if (storedUserId) {
      // TODO Check it
      if (me?.id && storedUserId !== me.id) {
        localStorage.wipe()
      }
    } else {
      localStorage.setItem(USER_ID_STORAGE_KEY, { userId: me?.id })
    }

    const onBeforeUnload = () => {
      localStorage.persist()
    }

    localStorage.load()
    window.addEventListener("beforeunload", onBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  }, [localStorage, me?.id, map])

  return children
}
