"use client"

import React from "react"

import { env } from "../../env.mjs"

import { FeatureFlagsIdentify } from "./FeatureFlagsIdentify"

import { LDProvider } from "launchdarkly-react-client-sdk"

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <LDProvider clientSideID={env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}>
      <FeatureFlagsIdentify />
      {children}
    </LDProvider>
  )
}
